<template>
  <div class="pt-5">
    <h1>Sign In</h1>
    <a-form
      layout="vertical"
      ref="signInFormRef"
      :model="signInFormState"
      :rules="signInRules"
      @finish="handleSignInFinish"
    >
      <a-form-item label="E-mail" required has-feedback name="account_email">
        <a-input
          v-model:value="signInFormState.account_email"
          placeholder="E-mail Address"
          type="email"
          size="large"
          autocomplete="master-email"
        />
      </a-form-item>
      <a-form-item
        label="Password"
        required
        has-feedback
        name="account_password"
      >
        <a-input
          v-model:value="signInFormState.account_password"
          placeholder="Password"
          type="password"
          size="large"
          autocomplete="master-password"
        />
      </a-form-item>
      <a-form-item>
        <div class="d-flex justify-content-center">
          <a-button :loading="loading" block type="primary" html-type="submit">
            Sign In
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
  <AlertModal
    v-model:visible="visibleModal"
    :message="alertMessage"
    @close-alert-modal="visibleModal = false"
  >
    <p class="text-center fw-light mb-1" style="white-space: pre">
      {{ alertMessage }}
    </p>
    <p class="text-center mb-3 fs-3">
      <a-typography-text type="danger"
        ><ExclamationCircleOutlined
      /></a-typography-text>
    </p>
  </AlertModal>
</template>
<script lang="ts">
import {
  defineComponent,
  watch,
  ref,
  reactive,
  UnwrapRef,
  computed,
} from "vue";
import { useStore } from 'vuex'

import { useRequest } from "vue-request";
import { useRouter } from "vue-router";
import { useAuth } from "@/_modules/auth";
import { authService, accountService } from "@/_services";
import { ISignInPayload, IUser } from "@/_utils/types";
import AlertModal from "@/components/AlertModal.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { key, MutationTypes } from '../../store'
export default defineComponent({
  components: {
    AlertModal,
    ExclamationCircleOutlined,
  },
  setup() {
    const router = useRouter();
    const store = useStore(key)

    const visibleModal = ref<boolean>(false);
    const loading = ref<boolean>(false);
      const url =  `${process.env.VUE_APP_EXTERNAL_BIGDATA_ADMIN}`;
    const alertMessage = ref<string>("");
        // const { setAccount, setToken } = useAuth();

    const { setAccount, setToken, setFeatureTokens } = useAuth();
    const signInFormRef = ref();
    const signInFormState: UnwrapRef<ISignInPayload> = reactive({
      account_email: "",
      account_password: "",
    });
    const signInRules = {
      account_email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input an e-mail address format",
          trigger: "blur",
        },
      ],
      account_password: [
        {
          required: true,
          message: "Please input your password",
          trigger: "blur",
        },
        {
          min: 6,
          message: "At least 6 characters",
          trigger: "submit",
        },
      ],
    };
    const {
      run,
      data: loginResult,
      error,
    } = useRequest(authService.login, {
      manual: true,
      onError: () => {
        loading.value = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = error.value as any;
        const { statusCode } = e;
        if (!statusCode) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (statusCode && statusCode === 401) {
          visibleModal.value = true;
          alertMessage.value = `Username or Password\nincorrect`;
        }
      },
      onSuccess: (loginResult) => {
        const { data } = loginResult;
        const {account, token } = data as IUser;
        setToken(token ?? null);
        // const store = useStore();
        // store.dispatch('login/login', { username: 'example', password: 'password' });
        // store.commit(MutationTypes.SET_ACCOUNT, account)
        console.log('feature',account,token)
        console.log('store: ' ,data)
        window.open(`${url}/sso?token=${data?.account.account_roles.find(
              (e) => e.role_featured === "BIGDATA"
            )?.token?.access_token}`, "_self");

        setFeatureTokens({
          bigData:
            data?.account.account_roles.find(
              (e) => e.role_featured === "BIGDATA"
            )?.token?.access_token ?? null,
          academy:
            data?.account.account_roles.find(
              (e) => e.role_featured === "ACADEMY"
            )?.token?.access_token ?? null,
          eLearning:
            data?.account.account_roles.find(
              (e) => e.role_featured === "ELEARNING"
            )?.token?.access_token ?? null,
            report:
            data?.account.account_roles.find(
              (e) => e.role_featured === "KPI"
            )?.token?.access_token ?? null,
        });
      },
    });

    const { data: account_test } = useRequest(
      accountService.getRolePermission,
      {
        ready: computed(() => !!loginResult.value),
        //   onSuccess: () => {
        //     loading.value = false;
        //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //     const datas: any = data.value?.data;
        //     console.log('setAccount',data);
        //     console.log('setAccount2',data.value);

        //     // const { account_role } = data.value?.data as any;
        //     setAccount({ ...datas?.account, account_roles: datas?.account_role });
        //     router.replace({ name: "home" });
        //   },
      }
    );
    watch([account_test], () => {
      loading.value = false;
      const datas: any = account_test.value?.data;
      setAccount({ ...datas?.account, account_roles: datas?.account_role });
      // router.replace({ name: "home" });
    });
    const handleSignInFinish = (values: ISignInPayload) => {
      loading.value = true;
      run({ ...values });
    };

    return {
      signInFormRef,
      signInRules,
      signInFormState,
      handleSignInFinish,
      loading,
      visibleModal,
      alertMessage,
    };
  },
});
</script>

